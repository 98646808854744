import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="magic-items"></a><h2>Magic Items</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Potions, Rings, &amp; Rods</span>
    </h2>
    <a id="potions-and-oils"></a><h5>POTIONS AND OILS</h5>
    <p className="initial">A potion is a magic liquid that produces its
effect when imbibed. Magic oils are similar to potions, except that
oils are applied externally rather than imbibed. A potion or oil can be
used only once. It can duplicate the effect of a spell of up to 3rd
level that has a casting time of less than 1 minute.</p>
    <p>Potions are like spells cast upon the imbiber. The character
taking the potion doesn&#8217;t get to make any decisions about the effect
&#8212;the caster who brewed the potion has already done so. The drinker of a
potion is both the effective target and the caster of the effect
(though the potion indicates the caster level, the drinker still
controls the effect).</p>
    <p>The person applying an oil is the effective caster, but the
object is the target.</p>
    <a id="potion-physical-description"></a><p><b>Physical Description</b>: A typical potion or oil consists
of 1 ounce of liquid held in a ceramic or glass vial fitted with a
tight stopper. The stoppered container is usually no more than 1 inch
wide and 2 inches high. The vial has AC 13, 1 hit point, hardness 1,
and a break DC of 12. Vials hold 1 ounce of liquid.</p>
    <a id="identifying-potions"></a><p><b>Identifying Potions</b>: In addition to the standard
methods of identification, PCs can sample from each container they find
to attempt to determine the nature of the liquid inside. An experienced
character learns to identify potions by memory&#8212;for example, the last
time she tasted a liquid that reminded her of almonds, it turned out to
be a potion of cure moderate wounds. </p>
    <a id="potion-activation"></a><p><b>Activation</b>: Drinking a potion or applying an oil
requires no special skill. The user merely removes the stopper and
swallows the potion or smears on the oil. The following rules govern
potion and oil use.</p>
    <p>Drinking a potion or using an oil on an item of gear is a
standard action. The potion or oil takes effect immediately. Using a
potion or oil provokes attacks of opportunity. A successful attack
(including grappling attacks) against the character forces a
Concentration check (as for casting a spell). If the character fails
this check, she cannot drink the potion. An enemy may direct an attack
of opportunity against the potion or oil container rather than against
the character. A successful attack of this sort can destroy the
container. </p>
    <p>A creature must be able to swallow a potion or smear on an
oil. Because of this, incorporeal creatures cannot use potions or oils.
    </p>
    <p>Any corporeal creature can imbibe a potion. The potion must be
swallowed. Any corporeal creature can use an oil.</p>
    <p>A character can carefully administer a potion to an
unconscious creature as a full-round action, trickling the liquid down
the creature&#8217;s throat. Likewise, it takes a full-round action to apply
an oil to an unconscious creature.</p>
    <a id="potion-descriptions"></a><h6>Potion Descriptions</h6>
    <p className="initial">The caster level for a standard potion is the
minimum caster level needed to cast the spell (unless otherwise
specified).</p>
    <a id="table-potions-and-oils"></a><p><b>Table: POTIONS AND OILS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "600px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>Minor</th>
          <th style={{
            "width": "12%"
          }}>Medium</th>
          <th style={{
            "width": "12%"
          }}>Major</th>
          <th style={{
            "width": "48%"
          }}>Potion or Oil</th>
          <th style={{
            "width": "16%"
          }}>Market Price</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;10</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsC.html#cure-light-wounds">Cure light wounds (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr>
          <td>11&#8211;13</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsDtoE.html#endure-elements">Endure elements (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr className="odd-row">
          <td>14&#8211;15</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#hide-from-animals">Hide from animals (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr>
          <td>16&#8211;17</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#hide-from-undead">Hide from undead (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr className="odd-row">
          <td>18&#8211;19</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#jump">Jump (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr>
          <td>20&#8211;22</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#mage-armor">Mage armor (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr className="odd-row">
          <td>23&#8211;25</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#magic-fang">Magic fang (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr>
          <td>26</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#magic-stone">Magic stone (oil)</a></td>
          <td>50 gp</td>
        </tr>
        <tr className="odd-row">
          <td>27&#8211;29</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#magic-weapon">Magic weapon (oil)</a></td>
          <td>50 gp</td>
        </tr>
        <tr>
          <td>30</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#pass-without-trace">Pass without trace (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr className="odd-row">
          <td>31&#8211;32</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#protection-from-evil">Protection from (alignment) (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr>
          <td>33&#8211;34</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#remove-fear">Remove fear (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr className="odd-row">
          <td>35</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsS.html#sanctuary">Sanctuary (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr>
          <td>36&#8211;38</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsS.html#shield-of-faith">Shield of faith +2 (potion)</a></td>
          <td>50 gp</td>
        </tr>
        <tr className="odd-row">
          <td>39</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsS.html#shillelagh">Shillelagh (oil)</a></td>
          <td>50 gp</td>
        </tr>
        <tr>
          <td>40&#8211;41</td>
          <td>01&#8211;02</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.htlm#bless-weapon">Bless weapon (oil)</a></td>
          <td>100 gp</td>
        </tr>
        <tr className="odd-row">
          <td>42&#8211;44</td>
          <td>03&#8211;04</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsDtoE.html#enlarge-person">Enlarge person (potion)</a></td>
          <td>250 gp</td>
        </tr>
        <tr>
          <td>45</td>
          <td>05</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#reduce-person">Reduce person (potion)</a></td>
          <td>250 gp</td>
        </tr>
        <tr className="odd-row">
          <td>46&#8211;47</td>
          <td>06</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.html#aid">Aid (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>48&#8211;50</td>
          <td>07</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.html#barkskin">Barkskin +2 (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>51&#8211;53</td>
          <td>08&#8211;10</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.html#bears-endurance">Bear&#8217;s endurance (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>54&#8211;56</td>
          <td>11&#8211;13</td>
          <td>01&#8211;02</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.html#blur">Blur (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>57&#8211;59</td>
          <td>14&#8211;16</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.html#bulls-strength">Bull&#8217;s strength (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>60&#8211;62</td>
          <td>17&#8211;19</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsC.html#cats-grace">Cat&#8217;s grace (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>63&#8211;67</td>
          <td>20&#8211;27</td>
          <td>03&#8211;07</td>
          <td><a style={{
              "color": "black"
            }} href="spellsC.html#cure-moderate-wounds">Cure moderate wounds (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>68</td>
          <td>28</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsDtoE.html#darkness">Darkness (oil)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>69&#8211;71</td>
          <td>29&#8211;30</td>
          <td>08&#8211;09</td>
          <td><a style={{
              "color": "black"
            }} href="spellsDtoE.html#darkvision">Darkvision (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>72&#8211;74</td>
          <td>31</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsDtoE.html#delay-poison">Delay poison (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>75&#8211;76</td>
          <td>32&#8211;33</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsDtoE.html#eagles-splendor">Eagle&#8217;s splendor (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>77&#8211;78</td>
          <td>34&#8211;35</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsFtoG.html#foxs-cunning">Fox&#8217;s cunning (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>79&#8211;81</td>
          <td>36&#8211;37</td>
          <td>10&#8211;11</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#invisibility">Invisibility (potion or oil)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>82&#8211;84</td>
          <td>38</td>
          <td>12</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#lesser-restoration">Lesser restoration (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>85&#8211;86</td>
          <td>39</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#levitate">Levitate (potion or oil)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>87</td>
          <td>40</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#misdirection">Misdirection (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>88&#8211;89</td>
          <td>41&#8211;42</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#owls-wisdom">Owl&#8217;s wisdom (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>90&#8211;91</td>
          <td>43</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#protection-from-arrows">Protection from arrows 10/magic (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>92&#8211;93</td>
          <td>44</td>
          <td>13</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#remove-paralysis">Remove paralysis (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>94&#8211;96</td>
          <td>45&#8211;46</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#resist-energy">Resist energy (type) 10 (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>97</td>
          <td>47&#8211;48</td>
          <td>14</td>
          <td><a style={{
              "color": "black"
            }} href="spellsS.html#shield-of-faith">Shield of faith +3 (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>98&#8211;99</td>
          <td>49</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsS.html#spider-climb">Spider climb (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr className="odd-row">
          <td>100</td>
          <td>50</td>
          <td>15</td>
          <td><a style={{
              "color": "black"
            }} href="spells#TtoZ.html#undetectable-alignment">Undetectable alignment (potion)</a></td>
          <td>300 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>51</td>
          <td>16</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.html#barkskin">Barkskin +3 (potion)</a></td>
          <td>600 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>52</td>
          <td>17&#8211;18</td>
          <td><a style={{
              "color": "black"
            }} href="spellsS.html#shield-of-faith">Shield of faith +4 (potion)</a></td>
          <td>600 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>53&#8211;55</td>
          <td>19&#8211;20</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#resist-energy">Resist energy (type) 20 (potion)</a></td>
          <td>700 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>56&#8211;60</td>
          <td>21&#8211;28</td>
          <td><a style={{
              "color": "black"
            }} href="spellsC.html#cure-serious-wounds">Cure serious wounds (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>61</td>
          <td>29</td>
          <td><a style={{
              "color": "black"
            }} href="spellsDtoE.html#daylight">Daylight (oil)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>62&#8211;64</td>
          <td>30&#8211;32</td>
          <td><a style={{
              "color": "black"
            }} href="spellsDtoE.html#displacement">Displacement (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>65</td>
          <td>33</td>
          <td><a style={{
              "color": "black"
            }} href="spellsFtoG.html#flame-arrow">Flame arrow (oil)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>66&#8211;68</td>
          <td>34&#8211;38</td>
          <td><a style={{
              "color": "black"
            }} href="spellsFtoG.html#fly">Fly (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>69</td>
          <td>39</td>
          <td><a style={{
              "color": "black"
            }} href="spellsFtoG.html#gaseous-form">Gaseous form (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>70&#8211;71</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#greater-magic-fang">Greater magic fang +1 (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>72&#8211;73</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#greater-magic-weapon">Greater magic weapon +1 (oil)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>74&#8211;75</td>
          <td>40&#8211;41</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#haste">Haste (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>76&#8211;78</td>
          <td>42&#8211;44</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#heroism">Heroism (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>79&#8211;80</td>
          <td>45&#8211;46</td>
          <td><a style={{
              "color": "black"
            }} href="spellsHtoL.html#keen-edge">Keen edge (oil)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>81</td>
          <td>47</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#magic-circle-against-evil">Magic circle against (alignment) (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>82&#8211;83</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#magic-vestment">Magic vestment +1 (oil)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>84&#8211;86</td>
          <td>48&#8211;50</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#neutralize-poison">Neutralize poison (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>87&#8211;88</td>
          <td>51&#8211;52</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#nondetection">Nondetection (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>89&#8211;91</td>
          <td>53&#8211;54</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#protection-from-energy">Protection from energy (type) (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>92&#8211;93</td>
          <td>55</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#rage">Rage (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>94</td>
          <td>56</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#remove-blindness-deafness">Remove blindness/deafness (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>95</td>
          <td>57</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#remove-curse">Remove curse (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>96</td>
          <td>58</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#remove-disease">Remove disease (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>97</td>
          <td>59</td>
          <td><a style={{
              "color": "black"
            }} href="spellsTtoZ.html#tongues">Tongues (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>98&#8211;99</td>
          <td>60</td>
          <td><a style={{
              "color": "black"
            }} href="spellsTtoZ.html#water-breathing">Water breathing (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>100</td>
          <td>61</td>
          <td><a style={{
              "color": "black"
            }} href="spellsTtoZ.html#water-walk">Water walk (potion)</a></td>
          <td>750 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>62&#8211;63</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.html#barkskin">Barkskin +4 (potion)</a></td>
          <td>900 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>64</td>
          <td><a style={{
              "color": "black"
            }} href="spellsS.html#shield-of-faith">Shield of faith +5 (potion)</a></td>
          <td>900 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>65</td>
          <td><a style={{
              "color": "black"
            }} href="spellsFtoG.html#good-hope">Good hope (potion)</a></td>
          <td>1,050 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>66&#8211;68</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#resist-energy">Resist energy (type) 30 (potion)</a></td>
          <td>1,100 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>69</td>
          <td><a style={{
              "color": "black"
            }} href="spellsAtoB.html#barkskin">Barkskin +5 (potion)</a></td>
          <td>1,200 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>70&#8211;73</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#greater-magic-fang">Greater magic fang +2 (potion)</a></td>
          <td>1,200 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>74&#8211;77</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#greater-magic-weapon">Greater magic weapon +2 (oil)</a></td>
          <td>1,200 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>78&#8211;81</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#magic-vestment">Magic vestment +2 (oil)</a></td>
          <td>1,200 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>82</td>
          <td><a style={{
              "color": "black"
            }} href="spellsPtoR.html#protection-from-arrows">Protection from arrows 15/magic (potion)</a></td>
          <td>1,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>83&#8211;85</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#greater-magic-fang">Greater magic fang +3 (potion)</a></td>
          <td>1,800 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>86&#8211;88</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMtoO.html#greater-magic-weapon">Greater magic weapon +3 (oil)</a></td>
          <td>1,800 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>89&#8211;91</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMto.html#magic-vestment">Magic vestment +3 (oil)</a></td>
          <td>1,800 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>92&#8211;93</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMto.html#greater-magic-fang">Greater magic fang +4 (potion)</a></td>
          <td>2,400 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>94&#8211;95</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMto.html#greater-magic-weapon">Greater magic weapon +4 (oil)</a></td>
          <td>2,400 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>96&#8211;97</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMto.html#magic-vestment">Magic vestment +4 (oil)</a></td>
          <td>2,400 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>98</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMto.html#greater-magic-fang">Greater magic fang +5 (potion)</a></td>
          <td>3,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>99</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMto.html#greater-magic-weapon">Greater magic weapon +5 (oil)</a></td>
          <td>3,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>100</td>
          <td><a style={{
              "color": "black"
            }} href="spellsMto.html#magic-vestment">Magic vestment +5 (oil)</a></td>
          <td>3,000 gp</td>
        </tr>
      </tbody>
    </table>
    <a id="rings"></a><h5>RINGS</h5>
    <p className="initial">Rings bestow magical powers upon their
wearers. Only a rare few have charges. Anyone can use a ring.</p>
    <p>A character can only effectively wear two magic rings. A third
magic ring doesn&#8217;t work if the wearer is already wearing two magic
rings.</p>
    <a id="ring-physical-description"></a><p><b>Physical Description</b>: Rings have no appreciable weight.
Although exceptions exist that are crafted from glass or bone, the vast
majority of rings are forged from metal&#8212;usually precious metals such as
gold, silver, and platinum. A ring has AC 13, 2 hit points, hardness
10, and a break DC of 25.</p>
    <a id="ring-activation"></a><p><b>Activation</b>: Usually, a ring&#8217;s ability is activated by a
command word (a standard action that does not provoke attacks of
opportunity) or it works continually. Some rings have exceptional
activation methods, according to their descriptions.</p>
    <a id="ring-special-qualities"></a><p><b>Special Qualities</b>: Roll d%. A result of 01 indicates
the ring is intelligent, 02&#8211;31 indicates that something (a design,
inscription, or the like) provides a clue to its function, and 32&#8211;100
indicates no special qualities. Intelligent items have extra abilities
and sometimes extraordinary powers and special purposes. Rings with
charges can never be intelligent.</p>
    <a id="table-rings"></a><p><b>Table: RINGS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "600px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "12%"
          }}>Minor</th>
          <th style={{
            "width": "12%"
          }}>Medium</th>
          <th style={{
            "width": "12%"
          }}>Major</th>
          <th style={{
            "width": "48%"
          }}>Ring</th>
          <th style={{
            "width": "16%"
          }}>Market Price</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;18</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-protection">Protection +1</a></td>
          <td>2,000 gp</td>
        </tr>
        <tr>
          <td>19&#8211;28</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-feather-falling">Feather falling</a></td>
          <td>2,200 gp</td>
        </tr>
        <tr className="odd-row">
          <td>29&#8211;36</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-sustenance">Sustenance</a></td>
          <td>2,500 gp</td>
        </tr>
        <tr>
          <td>37&#8211;44</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-climbing">Climbing</a></td>
          <td>2,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>45&#8211;52</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-jumping">Jumping</a></td>
          <td>2,500 gp</td>
        </tr>
        <tr>
          <td>53&#8211;60</td>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-swimming">Swimming</a></td>
          <td>2,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>61&#8211;70</td>
          <td>01&#8211;05</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-counterspells">Counterspells</a></td>
          <td>4,000 gp</td>
        </tr>
        <tr>
          <td>71&#8211;75</td>
          <td>06&#8211;08</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-mind-shielding">Mind shielding</a></td>
          <td>8,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>76&#8211;80</td>
          <td>09&#8211;18</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-protection">Protection +2</a></td>
          <td>8,000 gp</td>
        </tr>
        <tr>
          <td>81&#8211;85</td>
          <td>19&#8211;23</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-force-shield">Force shield</a></td>
          <td>8,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>86&#8211;90</td>
          <td>24&#8211;28</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-the-ram">Ram</a></td>
          <td>8,600 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>29&#8211;34</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-climbing-improved">Climbing, improved</a></td>
          <td>10,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>35&#8211;40</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-jumping-improved">Jumping, improved</a></td>
          <td>10,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>41&#8211;46</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-swimming-improved">Swimming, improved</a></td>
          <td>10,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>91&#8211;93</td>
          <td>47&#8211;51</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-animal-friendship">Animal friendship</a></td>
          <td>10,800 gp</td>
        </tr>
        <tr>
          <td>94&#8211;96</td>
          <td>50&#8211;56</td>
          <td>01&#8211;02</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-energy-resistance">Energy resistance, minor</a></td>
          <td>12,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>97&#8211;98</td>
          <td>57&#8211;61</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-chameleon-power">Chameleon power</a></td>
          <td>12,700 gp</td>
        </tr>
        <tr>
          <td>99&#8211;100</td>
          <td>62&#8211;66</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-water-walking">Water walking</a></td>
          <td>15,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>67&#8211;71</td>
          <td>03&#8211;07</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-protection">Protection +3</a></td>
          <td>18,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>72&#8211;76</td>
          <td>08&#8211;10</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-spell-storing-minor">Spell storing, minor</a></td>
          <td>18,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>77&#8211;81</td>
          <td>11&#8211;15</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-invisibility">Invisibility</a></td>
          <td>20,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>82&#8211;85</td>
          <td>16&#8211;19</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-wizardry">Wizardry (I)</a></td>
          <td>20,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>86&#8211;90</td>
          <td>20&#8211;25</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-evasion">Evasion</a></td>
          <td>25,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>91&#8211;93</td>
          <td>26&#8211;28</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-x-ray-vision">X-ray vision</a></td>
          <td>25,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>94&#8211;97</td>
          <td>29&#8211;32</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-blinking">Blinking</a></td>
          <td>27,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>98&#8211;100</td>
          <td>33&#8211;39</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-energy-resistance">Energy resistance, major</a></td>
          <td>28,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>40&#8211;49</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-protection">Protection +4</a></td>
          <td>32,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>50&#8211;55</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-wizardry">Wizardry (II)</a></td>
          <td>40,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>56&#8211;60</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-freedom-of-movement">Freedom of movement</a></td>
          <td>40,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>61&#8211;63</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-energy-resistance">Energy resistance, greater</a></td>
          <td>44,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>64&#8211;65</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-friend-shield">Friend shield (pair)</a></td>
          <td>50,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>66&#8211;70</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-protection">Protection +5</a></td>
          <td>50,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>71&#8211;74</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-shooting-stars">Shooting stars</a></td>
          <td>50,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>75&#8211;79</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-spell-storing">Spell storing</a></td>
          <td>50,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>80&#8211;83</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-wizardry">Wizardry (III)</a></td>
          <td>70,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>84&#8211;86</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-telekinesis">Telekinesis</a></td>
          <td>75,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>87&#8211;88</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-regeneration">Regeneration</a></td>
          <td>90,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>89</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-three-wishes">Three wishes</a></td>
          <td>97,950 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>90&#8211;92</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-spell-turning">Spell turning</a></td>
          <td>98,280 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>93&#8211;94</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-wizardry">Wizardry (IV)</a></td>
          <td>100,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>95</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-djinni-calling">Djinni calling</a></td>
          <td>125,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>96</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-elemental-command">Elemental command (air)</a></td>
          <td>200,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>97</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-elemental-command">Elemental command (earth)</a></td>
          <td>200,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>98</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-elemental-command">Elemental command (fire)</a></td>
          <td>200,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>&#8212;</td>
          <td>99</td>
          <td><a style={{
              "color": "black"
            }} href="#ring-of-elemental-command">Elemental command (water)</a></td>
          <td>200,000 gp</td>
        </tr>
        <tr>
          <td className="last-row">&#8212;</td>
          <td className="last-row">&#8212;</td>
          <td className="last-row">100</td>
          <td className="last-row"><a style={{
              "color": "black"
            }} href="#ring-of-spell-storing">Spell storing, major</a></td>
          <td className="last-row">200,000 gp</td>
        </tr>
      </tbody>
    </table>
    <a id="ring-descriptions"></a><h6>Ring Descriptions</h6>
    <p className="initial">Standard rings are described below.</p>
    <a id="ring-of-animal-friendship"></a><p><b>Animal Friendship</b>: On command, this ring affects an
animal as if the wearer had cast charm animal.</p>
    <p>Faint enchantment; CL 3rd; Forge Ring, charm animal; Price
10,800 gp.</p>
    <a id="ring-of-blinking"></a><p><b>Blinking</b>: On command, this ring makes the wearer blink,
as with the blink spell.</p>
    <p>Moderate transmutation; CL 7th; Forge Ring, blink; Price
27,000 gp.</p>
    <a id="ring-of-chameleon-power"></a><p><b>Chameleon Power</b>: As a free action, the wearer of this
ring can gain the ability to magically blend in with the surroundings.
This provides a +10 competence bonus on her Hide checks. As a standard
action, she can also command the ring to utilize the spell disguise
self as often as she wants.</p>
    <p>Faint illusion; CL 3rd; Forge Ring, disguise self,
invisibility; Price 12,700 gp.</p>
    <a id="ring-of-climbing"></a><p><b>Climbing</b>: This ring is actually a magic leather cord
that ties around a finger. It continually grants the wearer a +5
competence bonus on Climb checks.</p>
    <p>Faint transmutatation; CL 5th; Forge Ring, creator must have 5
ranks in the Climb skill; Price 2,500 gp.</p>
    <a id="ring-of-climbing-improved"></a><p><b>Climbing, Improved</b>: As climbing, except it grants a +10
competence bonus on its wearer&#8217;s Climb checks.</p>
    <p>Faint transmutation; CL 5th; Forge Ring, creator must have 10
ranks in the Climb skill; Price 10,000 gp.</p>
    <a id="ring-of-counterspells"></a><p><b>Counterspells</b>: This ring might seem to be a ring of
spell storing upon first examination. However, while it allows a single
spell of 1st through 6th level to be cast into it, that spell cannot be
cast out of the ring again. Instead, should that spell ever be cast
upon the wearer, the spell is immediately countered, as a counterspell
action, requiring no action (or even knowledge) on the wearer&#8217;s part.
Once so used, the spell cast within the ring is gone. A new spell (or
the same one as before) may be placed in it again.</p>
    <p>Moderate evocation; CL 11th; Forge Ring, imbue with spell
ability; Price 4,000 gp.</p>
    <a id="ring-of-djinni-calling"></a><p><b>Djinni Calling</b>: One of the many rings of fable, this
&#8220;genie&#8221; ring is most useful indeed. It serves as a special gate by
means of which a specific djinni can be called from the Elemental Plane
of Air. When the ring is rubbed (a standard action), the call goes out,
and the djinni appears on the next round. The djinni faithfully obeys
and serves the wearer of the ring, but never for more than 1 hour per
day. If the djinni of the ring is ever killed, the ring becomes
nonmagical and worthless.</p>
    <p>Strong conjuration; CL 17th; Forge Ring, gate; Price 125,000
gp.</p>
    <a id="ring-of-elemental-command"></a><p><b>Elemental Command</b>: All four kinds of elemental command
rings are very powerful. Each appears to be nothing more than a lesser
magic ring until fully activated (by meeting a special condition, such
as single-handedly slaying an elemental of the appropriate type or
exposure to a sacred material of the appropriate element), but each has
certain other powers as well as the following common properties.</p>
    <p>Elementals of the plane to which the ring is attuned can&#8217;t
attack the wearer, or even approach within 5 feet of him. If the wearer
desires, he may forego this protection and instead attempt to charm the
elemental (as charm monster, Will DC 17 negates). If the charm attempt
fails, however, absolute protection is lost and no further attempt at
charming can be made.</p>
    <p>Creatures from the plane to which the ring is attuned who
attack the wearer take a &#8211;1 penalty on their attack rolls. The ring
wearer makes applicable saving throws from the extraplanar creature&#8217;s
attacks with a +2 resistance bonus. He gains a +4 morale bonus on all
attack rolls against such creatures. Any weapon he uses bypasses the
damage reduction of such creatures, regardless of any qualities the
weapon may or may not have.</p>
    <p>The wearer of the ring is able to converse with creatures from
the plane to which his ring is attuned. These creatures recognize that
he wears the ring. They show a healthy respect for the wearer if
alignments are similar. If alignments are opposed, creatures fear the
wearer if he is strong. If he is weak, they hate and desire to slay him.</p>
    <p>The possessor of a ring of elemental command takes a saving
throw penalty as follows: </p>
    <a id="table-ring-of-elemental-command-saving-throw-penalty"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "400px"
    }}>
      <tbody>
        <tr>
          <th>Element</th>
          <th>Saving Throw Penalty</th>
        </tr>
        <tr className="odd-row">
          <td>Air</td>
          <td>&#8211;2 against earth-based effects</td>
        </tr>
        <tr>
          <td>Earth</td>
          <td>&#8211;2 against air- or electricity-based effects</td>
        </tr>
        <tr className="odd-row">
          <td>Fire</td>
          <td>&#8211;2 against water- or cold-based effects</td>
        </tr>
        <tr>
          <td className="last-row">Water</td>
          <td className="last-row">&#8211;2 against fire-based effects</td>
        </tr>
      </tbody>
    </table>
    <p>In addition to the powers described above, each specific ring
gives its wearer the following abilities according to its kind.</p>
    <p><i>Ring of Elemental Command (Air)</i></p>
    <ul>
      <li>Feather fall (unlimited use, wearer only)</li>
      <li>Resist energy (electricity) (unlimited use, wearer only)</li>
      <li>Gust of wind (twice per day)</li>
      <li>Wind wall (unlimited use)</li>
      <li>Air walk (once per day, wearer only)</li>
      <li>Chain lightning (once per week)</li>
    </ul>
    <p>The ring appears to be a ring of feather falling until a
certain condition is met to activate its full potential. It must be
reactivated each time a new wearer acquires it.</p>
    <p><i>Ring of Elemental Command (Earth)</i></p>
    <ul>
      <li>Meld into stone (unlimited use, wearer only)</li>
      <li>Soften earth or stone (unlimited use)</li>
      <li>Stone shape (twice per day)</li>
      <li>Stoneskin (once per week, wearer only)</li>
      <li>Passwall (twice per week)</li>
      <li>Wall of stone (once per day)</li>
    </ul>
    <p>The ring appears to be a ring of meld into stone until the
established condition is met.</p>
    <p><i>Ring of Elemental Command (Fire)</i></p>
    <ul>
      <li>Resist energy (fire) (as a major ring of energy resistance
[fire])</li>
      <li>Burning hands (unlimited use)</li>
      <li>Flaming sphere (twice per day)</li>
      <li>Pyrotechnics (twice per day)</li>
      <li>Wall of fire (once per day)</li>
      <li>Flame strike (twice per week)</li>
    </ul>
    <p>The ring appears to be a major ring of energy resistance
(fire) until the established condition is met.</p>
    <p><i>Ring of Elemental Command (Water)</i></p>
    <ul>
      <li>Water walk (unlimited use)</li>
      <li>Create water (unlimited use)</li>
      <li>Water breathing (unlimited use)</li>
      <li>Wall of ice (once per day)</li>
      <li>Ice storm (twice per week)</li>
      <li>Control water (twice per week)</li>
    </ul>
    <p>The ring appears to be a ring of water walking until the
established condition is met.</p>
    <p>Strong conjuration; CL 15th; Forge Ring, summon monster VI,
all appropriate spells; Price 200,000 gp.</p>
    <a id="ring-of-energy-resistance"></a><p><b>Energy Resistance</b>: This reddish iron ring continually
protects the wearer from damage from one type of energy&#8212;acid, cold,
electricity, fire, or sonic (chosen by the creator of the item;
determine randomly if found as part of a treasure hoard). Each time the
wearer would normally take such damage, subtract the ring&#8217;s resistance
value from the damage dealt.</p>
    <p>A minor ring of energy resistance grants 10 points of
resistance. A major ring of energy resistance grants 20 points of
resistance. A greater ring of energy resistance grants 30 points of
resistance.</p>
    <p>Faint (minor or major) or moderate (greater) abjuration; CL
3rd (minor), 7th (major), or 11th (greater); Forge Ring, resist energy;
Price 12,000 gp (minor), 28,000 gp (major), 44,000 gp (greater).</p>
    <a id="ring-of-evasion"></a><p><b>Evasion</b>: This ring continually grants the wearer the
ability to avoid damage as if she had evasion. Whenever she makes a
Reflex saving throw to determine whether she takes half damage, a
successful save results in no damage.</p>
    <p>Moderate transmutation; CL 7th; Forge Ring, jump; Price 25,000
gp.</p>
    <a id="ring-of-feather-falling"></a><p><b>Feather Falling</b>: This ring is crafted with a feather
pattern all around its edge. It acts exactly like a feather fall spell,
activated immediately if the wearer falls more than 5 feet.</p>
    <p>Faint transmutation; CL 1st; Forge Ring, feather fall; Price
2,200 gp.</p>
    <a id="ring-of-force-shield"></a><p><b>Force Shield</b>: An iron band, this simple ring generates
a shield-sized (and shield-shaped) wall of force that stays with the
ring and can be wielded by the wearer as if it were a heavy shield (+2
AC). This special creation has no armor check penalty or arcane spell
failure chance since it is weightless and encumbrance-free. It can be
activated and deactivated at will as a free action.</p>
    <p>Moderate evocation; CL 9th; Forge Ring, wall of force; Price
8,500 gp.</p>
    <a id="ring-of-freedom-of-movement"></a><p><b>Freedom of Movement</b>: This gold ring allows the wearer
to act as if continually under the effect of a freedom of movement
spell.</p>
    <p>Moderate abjuration; CL 7th; Forge Ring, freedom of movement;
Price 40,000 gp.</p>
    <a id="ring-of-friend-shield"></a><p><b>Friend Shield</b>: These curious rings always come in
pairs. A friend shield ring without its mate is useless. Either wearer
of one of a pair of the rings can, at any time, command his or her ring
to cast a shield other spell with the wearer of the mated ring as the
recipient. This effect has no range limitation. </p>
    <p>Moderate abjuration; CL 10th; Forge Ring, shield other; Price
50,000 gp (for a pair).</p>
    <a id="ring-of-invisibility"></a><p><b>Invisibility</b>: By activating this simple silver ring,
the wearer can benefit from invisibility, as the spell.</p>
    <p>Faint illusion; CL 3rd; Forge Ring, invisibility; Price 20,000
gp.</p>
    <a id="ring-of-jumping"></a><p><b>Jumping</b>: This ring continually allows the wearer to
leap about, providing a +5 competence bonus on all his Jump checks.</p>
    <p>Faint transmutation; CL 2nd; Forge Ring, creator must have 5
ranks in the Jump skill; Price 2,500 gp.</p>
    <a id="ring-of-jumping-improved"></a><p><b>Jumping, Improved</b>: As jumping, except it grants a +10
competence bonus on its wearer&#8217;s Jump check.</p>
    <p>Moderate transmutation; CL 7th; Forge Ring, creator must have
10 ranks in the Jump skill; Price 10,000 gp.</p>
    <a id="ring-of-mind-shielding"></a><p><b>Mind Shielding</b>: This ring is usually of fine
workmanship and wrought from heavy gold. The wearer is continually
immune to detect thoughts, discern lies, and any attempt to magically
discern her alignment.</p>
    <p>Faint aburation; CL 3rd; Forge Ring, nondetection; Price 8,000
gp.</p>
    <a id="ring-of-protection"></a><p><b>Protection</b>: This ring offers continual magical
protection in the form of a deflection bonus of +1 to +5 to AC.</p>
    <p>Faint abjuration; CL 5th; Forge Ring, shield of faith, caster
must be of a level at least three times greater than the bonus of the
ring; Price 2,000 gp (ring +1); 8,000 gp (ring +2); 18,000 gp (ring
+3); 32,000 gp (ring +4); 50,000 gp (ring +5).</p>
    <a id="ring-of-the-ram"></a><p><b>Ram</b>: The ring of the ram is an ornate ring forged of
hard metal, usually iron or an iron alloy. It has the head of a ram as
its device.</p>
    <p>The wearer can command the ring to give forth a ramlike force,
manifested by a vaguely discernible shape that resembles the head of a
ram or a goat. This force strikes a single target, dealing 1d6 points
of damage if 1 charge is expended, 2d6 points if 2 charges are used, or
3d6 points if 3 charges (the maximum) are used. Treat this as a ranged
attack with a 50-foot maximum range and no penalties for distance. </p>
    <p>The force of the blow is considerable, and those struck by the
ring are subject to a bull rush if within 30 feet of the ring-wearer.
(The ram has Strength 25 and is Large.) The ram gains a +1 bonus on the
bull rush attempt if 2 charges are expended, or +2 if 3 charges are
expended.</p>
    <p>In addition to its attack mode, the ring of the ram also has
the power to open doors as if it were a character with Strength 25. If
2 charges are expended, the effect is equivalent to a character with
Strength 27. If 3 charges are expended, the effect is that of a
character with Strength 29.</p>
    <p>A newly created ring has 50 charges. When all the charges are
expended, the ring becomes a nonmagical item.</p>
    <p>Moderate transmutation; CL 9th; Forge Ring, bull&#8217;s strength,
telekinesis; Price 8,600 gp.</p>
    <a id="ring-of-regeneration"></a><p><b>Regeneration</b>: This white gold ring continually allows a
living wearer to heal 1 point of damage per level every hour rather
than every day. (This ability cannot be aided by the Heal skill.)
Nonlethal damage heals at a rate of 1 point of damage per level every 5
minutes. If the wearer loses a limb, an organ, or any other body part
while wearing this ring, the ring regenerates it as the spell. In
either case, only damage taken while wearing the ring is regenerated.</p>
    <p>Strong conjuration; CL 15th; Forge Ring, regenerate; Price
90,000 gp.</p>
    <a id="ring-of-shooting-stars"></a><p><b>Shooting Stars</b>: This ring has two modes of operation,
one for being in shadowy darkness or outdoors at night and a second one
when the wearer is underground or indoors at night.</p>
    <p>During the night under the open sky or in areas of shadow or
darkness, the ring of shooting stars can perform the following
functions on command.</p>
    <ul>
      <li>Dancing lights (once per hour)</li>
      <li>Light (twice per night)</li>
      <li>Ball lightning (special, once per night)</li>
      <li>Shooting stars (special, three per week)</li>
    </ul>
    <p>The first special function, ball lightning, releases one to
four balls of lightning (ring wearer&#8217;s choice). These glowing globes
resemble dancing lights, and the ring wearer controls them in the same
fashion (see the dancing lights spell description). The spheres have a
120-foot range and a duration of 4 rounds. They can be moved at 120
feet per round. Each sphere is about 3 feet in diameter, and any
creature who comes within 5 feet of one causes its charge to dissipate,
taking electricity damage in the process according to the number of
balls created.</p>
    <a id="table-ring-of-shooting-stars"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "400px"
    }}>
      <tbody>
        <tr>
          <th>Number of Balls</th>
          <th>Damage per Ball</th>
        </tr>
        <tr className="odd-row">
          <td>4 lightning balls</td>
          <td>1d6 points of damage each</td>
        </tr>
        <tr>
          <td>3 lightning balls</td>
          <td>2d6 points of damage each</td>
        </tr>
        <tr className="odd-row">
          <td>2 lightning balls</td>
          <td>3d6 points of damage each</td>
        </tr>
        <tr>
          <td className="last-row">1 lightning ball</td>
          <td className="last-row">4d6 points of damage</td>
        </tr>
      </tbody>
    </table>
    <p>Once the ball lightning function is activated, the balls can
be released at any time before the sun rises. (Multiple balls can be
released in the same round.)</p>
    <p>The second special function produces three shooting stars that
can be released from the ring each week, simultaneously or one at a
time. They impact for 12 points of damage and spread (as a fireball) in
a 5-foot-radius sphere for 24 points of fire damage.</p>
    <p>Any creature struck by a shooting star takes full damage from
impact plus full fire damage from the spread unless it makes a DC 13
Reflex save. Creatures not struck but within the spread ignore the
impact damage and take only half damage from the fire spread on a
successful DC 13 Reflex save. Range is 70 feet, at the end of which the
shooting star explodes, unless it strikes a creature or object before
that. A shooting star always follows a straight line, and any creature
in its path must make a save or be hit by the projectile.</p>
    <p>Indoors at night, or underground, the ring of shooting stars
has the following properties.</p>
    <ul>
      <li>Faerie fire (twice per day)</li>
      <li>Spark shower (special, once per day)</li>
    </ul>
    <p>The spark shower is a flying cloud of sizzling purple sparks
that fan out from the ring for a distance of 20 feet in an arc 10 feet
wide. Creatures within this area take 2d8 points of damage each if not
wearing metal armor or carrying a metal weapon. Those wearing metal
armor and/or carrying a metal weapon take 4d8 points of damage.</p>
    <p>Strong evocation; CL 12th; Forge Ring, light, faerie fire,
fireball, lightning bolt; Price 50,000 gp.</p>
    <a id="ring-of-spell-storing-minor"></a><p><b>Spell Storing, Minor</b>: A minor ring of spell storing
contains up to three levels of spells that the wearer can cast. Each
spell has a caster level equal to the minimum level needed to cast that
spell. The user need not provide any material components or focus, or
pay an XP cost to cast the spell, and there is no arcane spell failure
chance for wearing armor (because the ring wearer need not gesture).
The activation time for the ring is same as the casting time for the
relevant spell, with a minimum of 1 standard action.</p>
    <p>For a randomly generated ring, treat it as a scroll to
determine what spells are stored in it. If you roll a spell that would
put the ring over the three-level limit, ignore that roll; the ring has
no more spells in it. (Not every newly discovered ring need be fully
charged.)</p>
    <p>A spellcaster can cast any spells into the ring, so long as
the total spell levels do not add up to more than three. Metamagic
versions of spells take up storage space equal to their spell level
modified by the metamagic feat. A spellcaster can use a scroll to put a
spell into the minor ring of spell storing.</p>
    <p>The ring magically imparts to the wearer the names of all
spells currently stored within it.</p>
    <p>Faint evocation; CL 5th; Forge Ring, imbue with spell ability;
Price 18,000 gp.</p>
    <a id="ring-of-spell-storing"></a><p><b>Spell Storing</b>: As the minor ring of spell storing,
except it holds up to five levels of spells.</p>
    <p>Moderate evocation; CL 9th; Forge Ring, imbue with spell
ability; Price 50,000 gp.</p>
    <a id="ring-of-spell-storing-major"></a><p><b>Spell Storing, Major</b>: As the minor ring of spell
storing, except it holds up to ten levels of spells.</p>
    <p>Strong evocation; CL 17th; Forge Ring, imbue with spell
ability; Price 200,000 gp.</p>
    <a id="ring-of-spell-turning"></a><p><b>Spell Turning</b>: Up to three times per day on command,
this simple platinum band automatically reflects the next nine levels
of spells cast at the wearer, exactly as if spell turning had been cast
upon the wearer.</p>
    <p>Strong abjuration; CL 13th; Forge Ring, spell turning; Price
98,280 gp.</p>
    <a id="ring-of-sustenance"></a><p><b>Sustenance</b>: This ring continually provides its wearer
with life-sustaining nourishment. The ring also refreshes the body and
mind, so that its wearer needs only sleep 2 hours per day to gain the
benefit of 8 hours of sleep. The ring must be worn for a full week
before it begins to work. If it is removed, the owner must wear it for
another week to reattune it to himself.</p>
    <p>Faint conjuration; CL 5th; Forge Ring, create food and water;
Price 2,500 gp.</p>
    <a id="ring-of-swimming"></a><p><b>Swimming</b>: This silver ring has a wave pattern etched
into the band. It continually grants the wearer a +5 competence bonus
on Swim checks.</p>
    <p>Faint transmutation; CL 2nd; Forge Ring, creator must have 5
ranks in the Swim skill; Price 2,500 gp.</p>
    <a id="ring-of-swimming-improved"></a><p><b>Swimming, Improved</b>: As swimming, except it grants a +10
competence bonus on its wearer&#8217;s Swim checks.</p>
    <p>Moderate transmutation; CL 7th; Forge Ring, creator must have
10 ranks in the Swim skill; Price 10,000 gp.</p>
    <a id="ring-of-telekinesis"></a><p><b>Telekinesis</b>: This ring allows the caster to use the
spell telekinesis on command.</p>
    <p>Moderate transmutation; CL 9th; Forge Ring, telekinesis; Price
75,000 gp.</p>
    <a id="ring-of-three-wishes"></a><p><b>Three Wishes</b>: This ring is set with three rubies. Each
ruby stores a wish spell, activated by the ring. When a wish is used,
that ruby disappears. For a randomly generated ring, roll 1d3 to
determine the remaining number of rubies. When all the wishes are used,
the ring becomes a nonmagical item.</p>
    <p>Strong evocation (if miracle is used); CL 20th; Forge Ring,
wish or miracle; Price 97,950 gp; Cost 11,475 gp + 15,918 XP.</p>
    <a id="ring-of-water-walking"></a><p><b>Water Walking</b>: This ring, set with an opal, allows the
wearer to continually utilize the effects of the spell water walk.</p>
    <p>Moderate transmutation; CL 9th; Forge Ring, water walk; Price
15,000 gp.</p>
    <a id="ring-of-wizardry"></a><p><b>Wizardry</b>: This special ring comes in four kinds (ring
of wizardry I, ring of wizardry II, ring of wizardry III, and ring of
wizardry IV), all of them useful only to arcane spellcasters. The
wearer&#8217;s arcane spells per day are doubled for one specific spell
level. A ring of wizardry I doubles 1st-level spells, a ring of
wizardry II doubles 2nd-level spells, a ring of wizardry III doubles
3rd-level spells, and a ring of wizardry IV doubles 4th-level spells.
Bonus spells from high ability scores or school specialization are not
doubled.</p>
    <p>Moderate (wizardry I) or strong (wizardry II&#8211;IV) (no school);
CL 11th (I), 14th (II), 17th (III), 20th (IV); Forge Ring, limited
wish; Price 20,000 gp (I), 40,000 gp (II), 70,000 gp (III), 100,000 gp
(IV).</p>
    <a id="ring-of-x-ray-vision"></a><p><b>X-Ray Vision</b>: On command, this ring gives its possessor
the ability to see into and through solid matter. Vision range is 20
feet, with the viewer seeing as if he were looking at something in
normal light even if there is no illumination. X-ray vision can
penetrate 1 foot of stone, 1 inch of common metal, or up to 3 feet of
wood or dirt. Thicker substances or a thin sheet of lead blocks the
vision.</p>
    <p>Using the ring is physically exhausting, causing the wearer 1
point of Constitution damage per minute after the first 10 minutes of
use in a single day.</p>
    <p>Moderate divination; CL 6th; Forge Ring, true seeing; Price
25,000 gp.</p>
    <a id="rods"></a><h5>RODS</h5>
    <p className="initial">Rods are scepterlike devices that have unique
magical powers and do not usually have charges. Anyone can use a rod.</p>
    <a id="rod-physical-description"></a><p><b>Physical Description</b>: Rods weigh approximately 5
pounds. They range from 2 feet to 3 feet long and are usually made of
iron or some other metal. (Many, as noted in their descriptions, can
function as light maces or clubs due to their sturdy construction.)
These sturdy items have AC 9, 10 hit points, hardness 10, and a break
DC of 27.</p>
    <a id="rod-activation"></a><p><b>Activation</b>: Details relating to rod use vary from item
to item. See the individual descriptions for specifics.</p>
    <a id="rod-special-qualities"></a><p><b>Special Qualities</b>: Roll d%. A 01 result indicates the
rod is intelligent, 02&#8211;31 indicates that something (a design,
inscription, or the like) provides a clue to its function, and 32&#8211;100
indicates no special qualities. Intelligent items have extra abilities
and sometimes extraordinary powers and special purposes.</p>
    <p>Rods with charges can never be intelligent.</p>
    <a id="table-rods"></a><p><b>Table: RODS</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "600px"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>Medium</th>
          <th style={{
            "width": "16%"
          }}>Major</th>
          <th style={{
            "width": "48%"
          }}>Rod</th>
          <th style={{
            "width": "20%"
          }}>Market Price</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;07</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-enlarge">Metamagic, Enlarge, lesser</a></td>
          <td>3,000 gp</td>
        </tr>
        <tr>
          <td>08&#8211;14</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-extend">Metamagic, Extend, lesser</a></td>
          <td>3,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>15&#8211;21</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-silent">Metamagic, Silent, lesser</a></td>
          <td>3,000 gp</td>
        </tr>
        <tr>
          <td>22&#8211;28</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#immovable-rod">Immovable</a></td>
          <td>5,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>29&#8211;35</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-empower">Metamagic, Empower, lesser</a></td>
          <td>9,000 gp</td>
        </tr>
        <tr>
          <td>36&#8211;42</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metal-and-mineral-detection">Metal and mineral detection</a></td>
          <td>10,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>43&#8211;53</td>
          <td>01&#8211;04</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-cancellation">Cancellation</a></td>
          <td>11,000 gp</td>
        </tr>
        <tr>
          <td>54&#8211;57</td>
          <td>05&#8211;06</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-enlarge">Metamagic, Enlarge</a></td>
          <td>11,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>58&#8211;61</td>
          <td>07&#8211;08</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-extend">Metamagic, Extend</a></td>
          <td>11,000 gp</td>
        </tr>
        <tr>
          <td>62&#8211;65</td>
          <td>09&#8211;10</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-silent">Metamagic, Silent</a></td>
          <td>11,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>66&#8211;71</td>
          <td>11&#8211;14</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-wonder">Wonder</a></td>
          <td>12,000 gp</td>
        </tr>
        <tr>
          <td>72&#8211;79</td>
          <td>15&#8211;18</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-the-python">Python</a></td>
          <td>13,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>80&#8211;83</td>
          <td>&#8212;</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-maximize">Metamagic, Maximize, lesser</a></td>
          <td>14,000 gp</td>
        </tr>
        <tr>
          <td>84&#8211;89</td>
          <td>19&#8211;21</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-flame-extinguishing">Flame extinguishing</a></td>
          <td>15,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>90&#8211;97</td>
          <td>22&#8211;25</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-the-viper">Viper</a></td>
          <td>19,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>26&#8211;30</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-enemy-detection">Enemy detection</a></td>
          <td>23,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>31&#8211;36</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-enlarge">Metamagic, Enlarge, greater</a></td>
          <td>24,500 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>37&#8211;42</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-extend">Metamagic, Extend, greater</a></td>
          <td>24,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>43&#8211;48</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-silent">Metamagic, Silent, greater</a></td>
          <td>24,500 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>49&#8211;53</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-splendor">Splendor</a></td>
          <td>25,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>54&#8211;58</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-withering">Withering</a></td>
          <td>25,000 gp</td>
        </tr>
        <tr>
          <td>98&#8211;99</td>
          <td>59&#8211;64</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-empower">Metamagic, Empower</a></td>
          <td>32,500 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>65&#8211;69</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-thunder-and-lightning">Thunder and lightning</a></td>
          <td>33,000 gp</td>
        </tr>
        <tr>
          <td>100</td>
          <td>70&#8211;73</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-quicken">Metamagic, Quicken, lesser</a></td>
          <td>35,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>74&#8211;77</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-negation">Negation</a></td>
          <td>37,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>78&#8211;80</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-absorption">Absorption</a></td>
          <td>50,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>81&#8211;84</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-flailing">Flailing</a></td>
          <td>50,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>85&#8211;86</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-maximize">Metamagic, Maximize</a></td>
          <td>54,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>87&#8211;88</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-rulership">Rulership</a></td>
          <td>60,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>89&#8211;90</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-security">Security</a></td>
          <td>61,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>91&#8211;92</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-lordly-might">Lordly might</a></td>
          <td>70,000 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>93&#8211;94</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-empower">Metamagic, Empower, greater</a></td>
          <td>73,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>95&#8211;96</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-quicken">Metamagic, Quicken</a></td>
          <td>75,500 gp</td>
        </tr>
        <tr>
          <td>&#8212;</td>
          <td>97&#8211;98</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-alertness">Alertness</a></td>
          <td>85,000 gp</td>
        </tr>
        <tr className="odd-row">
          <td>&#8212;</td>
          <td>99</td>
          <td><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-maximize">Metamagic, Maximize, greater</a></td>
          <td>121,500 gp</td>
        </tr>
        <tr>
          <td className="last-row">&#8212;</td>
          <td className="last-row">100</td>
          <td className="last-row"><a style={{
              "color": "black"
            }} href="#rod-of-metamagic-quicken">Metamagic, Quicken, greater</a></td>
          <td className="last-row">170,000 gp</td>
        </tr>
      </tbody>
    </table>
    <a id="rod-descriptions"></a><h6>Rod Descriptions</h6>
    <p className="initial">Although all rods are generally scepterlike,
their configurations and abilities run the magical gamut. Standard rods
are described below.</p>
    <a id="rod-of-absorption"></a><p><b>Absorption</b>: This rod acts as a magnet, drawing spells
or spell-like abilities into itself. The magic absorbed must be a
single-target spell or a ray directed at either the character
possessing the rod or her gear. The rod then nullifies the spell&#8217;s
effect and stores its potential until the wielder releases this energy
in the form of spells of her own. She can instantly detect a spell&#8217;s
level as the rod absorbs that spell&#8217;s energy. Absorption requires no
action on the part of the user if the rod is in hand at the time.</p>
    <p>A running total of absorbed (and used) spell levels should be
kept. The wielder of the rod can use captured spell energy to cast any
spell she has prepared, without expending the preparation itself. The
only restrictions are that the levels of spell energy stored in the rod
must be equal to or greater than the level of the spell the wielder
wants to cast, that any material components required for the spell be
present, and that the rod be in hand when casting. For casters such as
bards or sorcerers who do not prepare spells, the rod&#8217;s energy can be
used to cast any spell of the appropriate level or levels that they
know.</p>
    <p>A rod of absorption absorbs a maximum of fifty spell levels
and can thereafter only discharge any remaining potential it might
have. The rod cannot be recharged. The wielder knows the rod&#8217;s
remaining absorbing potential and current amount of stored energy.</p>
    <p>To determine the absorption potential remaining in a newly
found rod, roll d% and divide the result by 2. Then roll d% again: On a
result of 71&#8211;100, half the levels already absorbed by the rod are still
stored within.</p>
    <p>Strong abjuration; CL 15th;Craft Rod, spell turning; Price
50,000 gp.</p>
    <a id="rod-of-alertness"></a><p><b>Alertness</b>: This rod is indistinguishable from a +1
light mace. It has eight flanges on its macelike head. The rod bestows
a +1 insight bonus on initiative checks. If grasped firmly, the rod
enables the holder to use detect evil, detect good, detect chaos,
detect law, detect magic, discern lies, light, or see invisibility.
Each different use is a standard action.</p>
    <p>If the head of a rod of alertness is planted in the ground,
and the possessor wills it to alertness (a standard action), the rod
senses any creature within 120 feet who intends to harm the possessor.
At the same time, the rod creates the effect of a prayer spell upon all
creatures friendly to the possessor in a 20-foot radius. Immediately
thereafter, the rod sends forth a mental alert to these friendly
creatures, warning them of possible danger from the unfriendly creature
or creatures within the 120-foot radius. These effects last for 10
minutes, and the rod can perform this function once per day.</p>
    <p>Last, the rod can be used to simulate the casting of an
animate objects spell, utilizing any eleven (or fewer) Small objects
located roughly around the perimeter of a 5-foot-radius circle centered
on the rod when planted in the ground. Objects remain animated for 11
rounds. The rod can perform this function once per day.</p>
    <p>Moderate abjuration, divination, enchantment, and evocation;
CL 11th; Craft Rod, alarm, detect chaos, detect evil, detect good,
detect law, detect magic, discern lies, light, see invisibility,
prayer, animate objects; Price 85,000 gp.</p>
    <a id="rod-of-cancellation"></a><p><b>Cancellation</b>: This dreaded rod is a bane to magic
items, for its touch drains an item of all magical properties. The item
touched must make a DC 23 Will save to prevent the rod from draining
it. If a creature is holding it at the time, then the item can use the
holder&#8217;s Will save bonus in place of its own if the holder&#8217;s is better.
In such cases, contact is made by making a melee touch attack roll.
Upon draining an item, the rod itself becomes brittle and cannot be
used again. Drained items are only restorable by wish or miracle. (If a
sphere of annihilation and a rod of cancellation negate each other,
nothing can restore either of them.)</p>
    <p>Strong abjuration; CL 17th; Craft Rod, mage&#8217;s disjunction;
Price 11,000 gp.</p>
    <a id="rod-of-enemy-detection"></a><p><b>Enemy Detection</b>: This device pulses in the wielder&#8217;s
hand and points in the direction of any creature or creatures hostile
to the bearer of the device (nearest ones first). These creatures can
be invisible, ethereal, hidden, disguised, or in plain sight. Detection
range is 60 feet. If the bearer of the rod concentrates for a full
round, the rod pinpoints the location of the nearest enemy and
indicates how many enemies are within range. The rod can be used three
times each day, each use lasting up to 10 minutes. Activating the rod
is a standard action.</p>
    <p>Moderate divination; CL 10th; Craft Rod, true seeing; Price
23,500 gp.</p>
    <a id="rod-of-flailing"></a><p><b>Flailing</b>: Upon the command of its possessor, the rod
activates, changing from a normal-seeming rod to a +3 dire flail. The
dire flail is a double weapon, which means that each of the weapon&#8217;s
heads can be used to attack. The wielder can gain an extra attack (with
the second head) at the cost of making all attacks at a &#8211;2 penalty (as
if she had the Two-Weapon Fighting feat).</p>
    <p>Once per day the wielder can use a free action to cause the
rod to grant her a +4 deflection bonus to Armor Class and a +4
resistance bonus on saving throws for 10 minutes. The rod need not be
in weapon form to grant this benefit. Transforming it into a weapon or
back into a rod is a move action.</p>
    <p>Moderate enchantment; CL 9th; Craft Rod, Craft Magic Arms and
Armor, bless; Price 50,000 gp.</p>
    <a id="rod-of-flame-extinguishing"></a><p><b>Flame Extinguishing</b>: This rod can extinguish Medium or
smaller nonmagical fires with simply a touch (a standard action). For
the rod to be effective against other sorts of fires, the wielder must
expend 1 or more of the rod&#8217;s charges.</p>
    <p>Extinguishing a Large or larger nonmagical fire, or a magic
fire of Medium or smaller (such as that of a flaming weapon or a
burning hands spell), expends 1 charge. Continual magic flames, such as
those of a weapon or a fire creature, are suppressed for 6 rounds and
flare up again after that time. To extinguish an instantaneous fire
spell, the rod must be within the area of the effect and the wielder
must have used a ready action, effectively countering the entire spell.</p>
    <p>When applied to Large or larger magic fires, such as those
caused by fireball, flame strike, or wall of fire, extinguishing the
flames expends 2 charges from the rod.</p>
    <p>If the device is used upon a fire creature (a melee touch
attack), it deals 6d6 points of damage to the creature. This use
requires 3 charges.</p>
    <p>A rod of flame extinguishing has 10 charges when found. Spent
charges are renewed every day, so that a wielder can expend up to 10
charges in any 24-hour period.</p>
    <p>Strong transmutation; CL 12th; Craft Rod, pyrotechnics; Price
15,000 gp.</p>
    <a id="immovable-rod"></a><p><b>Immovable Rod</b>: This rod is a flat iron bar with a small
button on one end. When the button is pushed (a move action), the rod
does not move from where it is, even if staying in place defies
gravity. Thus, the owner can lift or place the rod wherever he wishes,
push the button, and let go. Several immovable rods can even make a
ladder when used together (although only two are needed). An immovable
rod can support up to 8,000 pounds before falling to the ground. If a
creature pushes against an immovable rod, it must make a DC 30 Strength
check to move the rod up to 10 feet in a single round.</p>
    <p>Moderate transmutation; CL 10th; Craft Rod, levitate; Price
5,000 gp.</p>
    <a id="rod-of-lordly-might"></a><p><b>Lordly Might</b>: This rod has functions that are
spell-like, and it can also be used as a magic weapon of various sorts.
It also has several more mundane uses. The rod of lordly might is
metal, thicker than other rods, with a flanged ball at one end and six
studlike buttons along its length. (Pushing any of the rod&#8217;s buttons is
equivalent to drawing a weapon.) It weighs 10 pounds.</p>
    <p>The following spell-like functions of the rod can each be used
once per day.</p>
    <ul>
      <li>
        <p>Hold person upon touch, if the wielder so commands (Will
DC 14 negates). The wielder must choose to use this power and then
succeed on a melee touch attack to activate the power. If the attack
fails, the effect is lost.</p>
      </li>
      <li>
        <p>Fear upon all enemies viewing it, if the wielder so
desires (10-foot maximum range, Will DC 16 partial). Invoking this
power is a standard action.</p>
      </li>
      <li>
        <p>Deal 2d4 hit points of damage to an opponent on a
successful touch attack (Will DC 17 half ) and cure the wielder of a
like amount of damage. The wielder must choose to use this power before
attacking, as with hold person.</p>
      </li>
    </ul>
    <p>The following weapon functions of the rod have no limit on the
number of times they can be employed.</p>
    <ul>
      <li>
        <p>In its normal form, the rod can be used as a +2 light mace.</p>
      </li>
      <li>
        <p>When button 1 is pushed, the rod becomes a +1 flaming
longsword. A blade springs from the ball, with the ball itself becoming
the sword&#8217;s hilt. The weapon lengthens to an overall length of 4 feet.</p>
      </li>
      <li>
        <p>When button 2 is pushed, the rod becomes a +4 battleaxe. A
wide blade springs forth at the ball, and the whole lengthens to 4 feet.</p>
      </li>
      <li>
        <p>When button 3 is pushed, the rod becomes a +3 shortspear
or +3 longspear. The spear blade springs forth, and the handle can be
lengthened up to 12 feet (wielder&#8217;s choice), for an overall length of
from 6 feet to 15 feet. At its 15-foot length, the rod is suitable for
use as a lance.</p>
      </li>
    </ul>
    <p>The following other functions of the rod also have no limit on
the number of times they can be employed.</p>
    <ul>
      <li>
        <p>Climbing pole/ladder. When button 4 is pushed, a spike
that can anchor in granite is extruded from the ball, while the other
end sprouts three sharp hooks. The rod lengthens to anywhere between 5
and 50 feet in a single round, stopping when button 4 is pushed again.
Horizontal bars three inches long fold out from the sides, 1 foot
apart, in staggered progression. The rod is firmly held by the spike
and hooks and can bear up to 4,000 pounds. The wielder can retract the
pole by pushing button 5.</p>
      </li>
      <li>
        <p>The ladder function can be used to force open doors. The
wielder plants the rod&#8217;s base 30 feet or less from the portal to be
forced and in line with it, then pushes button 4. The force exerted has
a Strength modifier of +12.</p>
      </li>
      <li>
        <p>When button 6 is pushed, the rod indicates magnetic north
and gives the wielder a knowledge of his approximate depth beneath the
surface or height above it.</p>
      </li>
    </ul>
    <p>Strong enchantment, evocation, necromancy, and transmutation;
CL 19th; Craft Rod, Craft Magic Arms and Armor, inflict light wounds,
bull&#8217;s strength, flame blade, hold person, fear; Price 70,000 gp.</p>
    <p><b><a id="rod-of-metal-and-mineral-detection"></a>Metal and Mineral Detection</b>: This rod pulses in the
wielder&#8217;s hand and points to the largest mass of metal within 30 feet.
However, the wielder can concentrate on a specific metal or mineral. If
the specific mineral is within 30 feet, the rod points to any places it
is located, and the rod wielder knows the approximate quantity as well.
If more than one deposit of the specified metal or mineral is within
range, the rod points to the largest cache first. Each operation
requires a full-round action.</p>
    <p>Moderate divination; CL 9th; Craft Rod, locate object; Price
10,500 gp.</p>
    <a id="metamagic-rods"></a><p><b>Metamagic Rods</b>: Metamagic rods hold the essence of a
metamagic feat but do not change the spell slot of the altered spell.
All the rods described here are use-activated (but casting spells in a
threatened area still draws an attack of opportunity). A caster may
only use one metamagic rod on any given spell, but it is permissible to
combine a rod with metamagic feats possessed by the rod&#8217;s wielder. In
this case, only the feats possessed by the wielder adjust the spell
slot of the spell being cast.</p>
    <p>Possession of a metamagic rod does not confer the associated
feat on the owner, only the ability to use the given feat a specified
number of times per day. A sorcerer still must take a full-round action
when using a metamagic rod, just as if using a metamagic feat he
possesses.</p>
    <p><i>Lesser and Greater Metamagic Rods</i>: Normal metamagic
rods can be used with spells of 6th level or lower. Lesser rods can be
used with spells of 3rd level or lower, while greater rods can be used
with spells of 9th level or lower.</p>
    <a id="rod-of-metamagic-empower"></a><p><b>Metamagic, Empower</b>: The wielder can cast up to three
spells per day that are empowered as though using the <a href="featsAll.html#empower-spell">Empower Spell</a>
feat.</p>
    <p>Strong (no school); CL 17th; Craft Rod, Empower Spell; Price
9,000 gp (lesser), 32,500 gp (normal), 73,000 gp (greater).</p>
    <a id="rod-of-metamagic-enlarge"></a><p><b>Metamagic, Enlarge</b>: The wielder can cast up to three
spells per day that are enlarged as though using the <a href="featsAll.html#enlarge-spell">Enlarge Spell</a> feat.</p>
    <p>Strong (no school); CL 17th; Craft Rod, Enlarge Spell; Price
3,000 gp (lesser), 11,000 gp (normal), 24,500 gp (greater).</p>
    <a id="rod-of-metamagic-extend"></a><p><b>Metamagic, Extend</b>: The wielder can cast up to three
spells per day that are extended as though using the <a href="featsAll.html#extend-spell">Extend Spell</a> feat.</p>
    <p>Strong (no school); CL 17th; Craft Rod, Extend Spell; Price
3,000 gp (lesser), 11,000 gp (normal), 24,500 gp (greater).</p>
    <a id="rod-of-metamagic-maximize"></a><p><b>Metamagic, Maximize</b>: The wielder can cast up to three
spells per day that are maximized as though using the <a href="featsAll.html#maximize-spell">Maximize Spell</a>
feat.</p>
    <p>Strong (no school); CL 17th; Craft Rod, Maximize Spell feat;
Price 14,000 gp (lesser), 54,000 gp (normal), 121,500 gp (greater).</p>
    <a id="rod-of-metamagic-quicken"></a><p><b>Metamagic, Quicken</b>: The wielder can cast up to three
spells per day that are quickened as though using the <a href="featsAll.html#quicken-spell">Quicken Spell</a>
feat.</p>
    <p>Strong (no school); CL 17th; Craft Rod, Quicken Spell; Price
35,000 gp (lesser), 75,500 gp (normal), 170,000 gp (greater).</p>
    <a id="rod-of-metamagic-silent"></a><p><b>Metamagic, Silent</b>: The wielder can cast up to three
spells per day without verbal components as though using the <a href="featsAll.html#silent-spell">Silent
Spell</a> feat.</p>
    <p>Strong (no school); CL 17th; Craft Rod, Silent Spell; Price
3,000 gp (lesser), 11,000 gp (normal), 24,500 gp (greater).</p>
    <a id="rod-of-negation"></a><p><b>Negation</b>: This device negates the spell or spell-like
function or functions of magic items. The wielder points the rod at the
magic item, and a pale gray beam shoots forth to touch the target
device, attacking as a ray (a ranged touch attack). The ray functions
as a greater dispel magic spell, except it only affects magic items. To
negate instantaneous effects from an item, the rod wielder needs to
have used a ready action. The dispel check uses the rod&#8217;s caster level
(15th). The target item gets no saving throw, although the rod can&#8217;t
negate artifacts (even minor artifacts). The rod can function three
times per day.</p>
    <p>Strong varied; CL 15th; Craft Rod, dispel magic, and limited
wish or miracle; Price 37,000 gp.</p>
    <a id="rod-of-the-python"></a><p><b>Python</b>: This rod is longer than normal rods. It is
about 4 feet long and weighs 10 pounds. It strikes as a +1/+1
quarterstaff. If the user throws the rod to the ground (a standard
action), it grows to become a giant constrictor snake by the end of the
round. The python obeys all commands of the owner. (In animal form, it
retains the +1 enhancement bonus on attacks and damage possessed by the
rod form.) The serpent returns to rod form (a full-round action)
whenever the wielder desires, or whenever it moves farther than 100
feet from the owner. If the snake form is slain, it returns to rod form
and cannot be activated again for three days. A python rod only
functions if the possessor is good.</p>
    <p>Moderate transmutation; CL 10th; Craft Rod, Craft Magic Arms
and Armor, baleful polymorph, creator must be good; Price 13,000 gp.</p>
    <a id="rod-of-rulership"></a><p><b>Rulership</b>: This rod looks like a royal scepter worth at
least 5,000 gp in materials and workmanship alone. The wielder can
command the obedience and fealty of creatures within 120 feet when she
activates the device (a standard action). Creatures totaling 300 Hit
Dice can be ruled, but creatures with Intelligence scores of 12 or
higher are entitled to a DC 16 Will save to negate the effect. Ruled
creatures obey the wielder as if she were their absolute sovereign.
Still, if the wielder gives a command that is contrary to the nature of
the creatures commanded, the magic is broken. The rod can be used for
500 total minutes before crumbling to dust. This duration need not be
continuous.</p>
    <p>Strong enchantment; CL 20th; Craft Rod, mass charm monster;
Price 60,000 gp; Cost 27,500 gp + 2,200 XP.</p>
    <a id="rod-of-security"></a><p><b>Security</b>: This item creates a nondimensional space, a
pocket paradise. There the rod&#8217;s possessor and as many as 199 other
creatures can stay in complete safety for a period of time, up to 200
days divided by the number of creatures affected. All fractions are
rounded down.</p>
    <p>In this pocket paradise, creatures don&#8217;t age, and natural
healing take place at twice the normal rate. Fresh water and food
(fruits and vegetables only) are in abundance. The climate is
comfortable for all creatures involved.</p>
    <p>Activating the rod (a standard action) causes the wielder and
all creatures touching the rod to be transported instantaneously to the
paradise. Members of large groups can hold hands or otherwise maintain
physical contact, allowing all connected creatures in a circle or a
chain to be affected by the rod. Unwilling creatures get a DC 17 Will
save to negate the effect. If such a creature succeeds on its save,
other creatures beyond that point in a chain can still be affected by
the rod.</p>
    <p>When the rod&#8217;s effect expires or is dispelled, all the
affected creatures instantly reappear in the location they occupied
when the rod was activated. If something else occupies the space that a
traveler would be returning to, then his body is displaced a sufficient
distance to provide the space required for reentry. The rod&#8217;s possessor
can dismiss the effect whenever he wishes before the maximum time
period expires, but the rod can only be activated once per week.</p>
    <p>Strong conjuuration; CL 20th; Craft Rod, gate; Price 61,000 gp.</p>
    <a id="rod-of-splendor"></a><p><b>Splendor</b>: The possessor of this rod gains a +4
enhancement bonus to her Charisma score for as long as she holds or
carries the item. Once per day, the rod creates and garbs her in
clothing of the finest fabrics, plus adornments of furs and jewels. </p>
    <p>Apparel created by the magic of the rod remains in existence
for 12 hours. However, if the possessor attempts to sell or give away
any part of it, to use it for a spell component, or the like, all the
apparel immediately disappears. The same applies if any of it is
forcibly taken from her.</p>
    <p>The value of noble garb created by the rod ranges from 7,000
to 10,000 gp (1d4+6 x 1,000 gp)&#8212;1,000 gp for the fabric alone, 5,000 gp
for the furs, and the rest for the jewel trim (maximum of twenty gems,
maximum value 200 gp each).</p>
    <p>In addition, the rod has a second special power, usable once
per week. Upon command, it creates a palatial tent&#8212;a huge pavilion of
silk 60 feet across. Inside the tent are temporary furnishings and food
suitable to the splendor of the pavilion and sufficient to entertain as
many as one hundred persons. The tent and its trappings last for one
day. At the end of that time, the tent and all objects associated with
it (including any items that were taken out of the tent) disappear.</p>
    <p>Strong conjuration and transmutation; CL 12th; Craft Rod,
eagle&#8217;s splendor, fabricate, major creation; Price 25,000 gp.</p>
    <a id="rod-of-thunder-and-lightning"></a><p><b>Thunder and Lightning</b>: Constructed of iron set with
silver rivets, this rod has the properties of a +2 light mace. Its
other magical powers are as follows.</p>
    <ul>
      <li>
        <p><i>Thunder</i>: Once per day, the rod can strike as a +3
light mace, and the opponent struck is stunned from the noise of the
rod&#8217;s impact (Fortitude DC 16 negates). Activating this power counts as
a free action, and it works if the wielder strikes an opponent within 1
round.</p>
      </li>
      <li>
        <p><i>Lightning</i>: Once per day, when the wielder desires,
a short spark of electricity can leap forth when the rod strikes an
opponent to deal the normal damage for a +2 light mace (1d6+2) and an
extra 2d6 points of electricity damage. Even when the rod might not
score a normal hit in combat, if the roll was good enough to count as a
successful melee touch attack hit, then the 2d6 points of electricity
damage still applies. The wielder activates this power as a free
action, and it works if he strikes an opponent within 1 round.</p>
      </li>
      <li>
        <p><i>Thunderclap</i>: Once per day as a standard action, the
wielder can cause the rod to give out a deafening noise, just as a
shout spell (Fortitude DC 16 partial, 2d6 points of sonic damage,
target deafened for 2d6 rounds).</p>
      </li>
      <li>
        <p><i>Lightning Stroke</i>: Once per day as a standard
action, the wielder can cause the rod to shoot out a 5-foot-wide
lightning bolt (9d6 points of electricity damage, Reflex DC 16 half )
to a range of 200 feet.</p>
      </li>
      <li>
        <p><i>Thunder and Lightning</i>: Once per week as a standard
action, the wielder of the rod can combine the thunderclap described
above with a lightning bolt, as in the lightning stroke. The
thunderclap affects all within 10 feet of the bolt. The lightning
stroke deals 9d6 points of electricity damage (count rolls of 1 or 2 as
rolls of 3, for a range of 27 to 54 points), and the thunderclap deals
2d6 points of sonic damage. A single DC 16 Reflex save applies for both
effects.</p>
      </li>
    </ul>
    <p>Moderate evocation; CL 9th; Craft Rod, Craft Magic Arms and
Armor, lightning bolt, shout; Price 33,000 gp.</p>
    <a id="rod-of-the-viper"></a><p><b>Viper</b>: This rod strikes as a +2 heavy mace. Once per
day, upon command, the head of the rod becomes that of an actual
serpent for 10 minutes. During this period, any successful strike with
the rod deals its usual damage and also poisons the creature hit. The
poison deals 1d10 points of Constitution damage immediately (Fortitude
DC 14 negates) and another 1d10 points of Constitution damage 1 minute
later (Fortitude DC 14 negates). The rod only functions if its
possessor is evil.</p>
    <p>Moderate necromancy; CL 10th; Craft Rod, Craft Magic Arms and
Armor, poison, creator must be evil; Price 19,000 gp.</p>
    <a id="rod-of-withering"></a><p><b>Withering</b>: A rod of withering acts as a +1 light mace
that deals no hit point damage. Instead, the wielder deals 1d4 points
of Strength damage and 1d4 points of Constitution damage to any
creature she touches with the rod (by making a melee touch attack). If
she scores a critical hit, the damage from that hit is permanent
ability drain. In either case, the defender negates the effect with a
DC 17 Fortitude save.</p>
    <p>Strong necromancy; CL 13th; Craft Rod, Craft Magic Arms and
Armor, contagion; Price 25,000 gp.</p>
    <a id="rod-of-wonder"></a><p><b>Wonder</b>: A rod of wonder is a strange and unpredictable
device that randomly generates any number of weird effects each time it
is used. (Activating the rod is a standard action.) Typical powers of
the rod include the following.</p>
    <a id="table-rod-of-wonder"></a><table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "8%"
          }}>d%</th>
          <th style={{
            "width": "92%"
          }}>Wondrous Effect</th>
        </tr>
        <tr className="odd-row">
          <td>01&#8211;05</td>
          <td>Slow creature pointed at for 10 rounds (Will DC 15
negates).</td>
        </tr>
        <tr>
          <td>06&#8211;10</td>
          <td>Faerie fire surrounds the target.</td>
        </tr>
        <tr className="odd-row">
          <td>11&#8211;15</td>
          <td>Deludes wielder for 1 round into believing the rod
functions as indicated by a second die roll (no save).</td>
        </tr>
        <tr>
          <td>16&#8211;20</td>
          <td>Gust of wind, but at windstorm force (Fortitude DC 14
negates).</td>
        </tr>
        <tr className="odd-row">
          <td>21&#8211;25</td>
          <td>Wielder learns target&#8217;s surface thoughts (as with
detect thoughts) for 1d4 rounds (no save).</td>
        </tr>
        <tr>
          <td>26&#8211;30</td>
          <td>Stinking cloud at 30-ft. range (Fortitude DC 15
negates).</td>
        </tr>
        <tr className="odd-row">
          <td>31&#8211;33</td>
          <td>Heavy rain falls for 1 round in 60-ft. radius centered
on rod wielder.</td>
        </tr>
        <tr>
          <td>34&#8211;36</td>
          <td>Summon an animal&#8212;a rhino (01&#8211;25 on d%), elephant
(26&#8211;50), or mouse (51&#8211;100).</td>
        </tr>
        <tr className="odd-row">
          <td>37&#8211;46</td>
          <td>Lightning bolt (70 ft. long, 5 ft. wide), 6d6 damage
(Reflex DC 15 half).</td>
        </tr>
        <tr>
          <td>47&#8211;49</td>
          <td>Stream of 600 large butterflies pours forth and
flutters around for 2 rounds, blinding everyone (including wielder)
within 25 ft. (Reflex DC 14 negates).</td>
        </tr>
        <tr className="odd-row">
          <td>50&#8211;53</td>
          <td>Enlarge person if within 60 ft. of rod (Fortitude DC 13
negates).</td>
        </tr>
        <tr>
          <td>54&#8211;58</td>
          <td>Darkness, 30-ft.-diameter hemisphere, centered 30 ft.
away from rod.</td>
        </tr>
        <tr className="odd-row">
          <td>59&#8211;62</td>
          <td>Grass grows in 160-sq.-ft. area before the rod, or
grass existing there grows to ten times normal size.</td>
        </tr>
        <tr>
          <td>63&#8211;65</td>
          <td>Turn ethereal any nonliving object of up to 1,000 lb.
mass and up to 30 cu. ft. in size.</td>
        </tr>
        <tr className="odd-row">
          <td>66&#8211;69</td>
          <td>Reduce wielder to 1/12 height (no save).</td>
        </tr>
        <tr>
          <td>70&#8211;79</td>
          <td>Fireball at target or 100 ft. straight ahead, 6d6
damage (Reflex DC 15 half).</td>
        </tr>
        <tr className="odd-row">
          <td>80&#8211;84</td>
          <td>Invisibility covers rod wielder.</td>
        </tr>
        <tr>
          <td>85&#8211;87</td>
          <td>Leaves grow from target if within 60 ft. of rod. These
last 24 hours.</td>
        </tr>
        <tr className="odd-row">
          <td>88&#8211;90</td>
          <td>10&#8211;40 gems, value 1 gp each, shoot forth in a
30-ft.-long stream. Each gem deals 1 point of damage to any creature in
its path: Roll 5d4 for the number of hits and divide them among the
available targets.</td>
        </tr>
        <tr>
          <td>91&#8211;95</td>
          <td>Shimmering colors dance and play over a
40-ft.-by-30-ft. area in front of rod. Creatures therein are blinded
for 1d6 rounds (Fortitude DC 15 negates).</td>
        </tr>
        <tr className="odd-row">
          <td>96&#8211;97</td>
          <td>Wielder (50% chance) or target (50% chance) turns
permanently blue, green, or purple (no save).</td>
        </tr>
        <tr>
          <td className="last-row">98&#8211;100</td>
          <td className="last-row">Flesh to stone (or stone to flesh if
target is stone already) if target is within 60 ft. (Fortitude DC 18
negates).</td>
        </tr>
      </tbody>
    </table>
    <p>Moderate enchantment; CL 10th; Craft Rod, confusion, creator
must be chaotic; Price 12,000 gp.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      